import React, { Component } from "react"
import { connect } from "react-redux"
import AddressInput from "../components/base/AddressInput"
import Button from "../components/base/Button"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import Input from "../components/base/Input"
import Notification, {
  NotificationAttribute,
} from "../components/base/Notification"
import Overlay from "../components/base/Overlay"
import Select, { Option } from "../components/base/Select"
import UploadInput from "../components/base/UploadInput"
import SEO from "../components/base/seo"
import Whatsapp from "../components/marketing/Whatsapp"
import { PreferredTime, PreferrredDay } from "../models/Schedule"
import { Address } from "../models/User"
import {
  checkAppointmentUserExist,
  createSchedule,
  handleScheduleCondition,
} from "../store/actions/scheduleActions"
import { authStateInterface } from "../store/reducers/authReducer"
import { utilsStateInterface } from "../store/reducers/utilsReducer"
import { utilPhonePlaceholder } from "../utils/region"

const preferredDayOptions: Option[] = Object.keys(PreferrredDay).map(
  eachPreferredDayKey => {
    return {
      key: eachPreferredDayKey,
      title: PreferrredDay[eachPreferredDayKey as keyof typeof PreferrredDay],
    }
  }
)

const preferredTimeOptions: Option[] = Object.keys(PreferredTime).map(
  eachPreferredTimeKey => {
    return {
      key: eachPreferredTimeKey,
      title: PreferredTime[eachPreferredTimeKey as keyof typeof PreferredTime],
    }
  }
)

export interface AppointmentState {
  phone: string
  name: string
  address: Address
  image: File | ""
  preferredDay: keyof typeof PreferrredDay | ""
  preferredTime: keyof typeof PreferredTime | ""
  additionRemark: string
  estimatedWeight: number
  email?: string
}

export interface AppointmentStateError {
  phoneError: string
  nameError: string
  addressError: string
  imageError: string
  preferredDayError: string
  preferredTimeError: string
  estimatedWeightError: string
}

interface State {
  appointmentState: AppointmentState
  appointmentStateError: AppointmentStateError
  notificationAttribute: NotificationAttribute
  image: File | ""
  usedCurrentPhone: boolean
  usedCurrentAddress: boolean
  loading: boolean
  loadingText: string
}

interface Props {
  utilsStore: utilsStateInterface
  authStore: authStateInterface
  updatePromotionCode: (promoCode: string) => void
}

class CreateAppointmentPage extends Component<Props> {
  timeout: any = null
  state: State = {
    appointmentState: {
      phone: "",
      name: "",
      address: {
        name: "",
        lat: 0,
        lng: 0,
      },
      image: "",
      preferredDay: "",
      preferredTime: "",
      additionRemark: "",
      estimatedWeight: 0,
    },
    appointmentStateError: {
      phoneError: "",
      nameError: "",
      addressError: "",
      imageError: "",
      preferredDayError: "",
      preferredTimeError: "",
      estimatedWeightError: "",
    },
    notificationAttribute: {
      title: "",
      desc: "",
      type: "",
      visible: false,
    },
    image: "",
    usedCurrentPhone: false,
    usedCurrentAddress: false,
    loading: false,
    loadingText: "",
  }

  handleCloneAppointmentState = () => {
    const clonedAppointmentState = JSON.parse(
      JSON.stringify(this.state.appointmentState)
    )
    return clonedAppointmentState
  }

  handleSelectChange = (id: string, key: string) => {
    const clonedSchduleState = this.handleCloneAppointmentState()
    clonedSchduleState[id] = key
    this.setState({
      appointmentState: clonedSchduleState,
    })
  }

  handleInputChange = (e: any) => {
    const clonedSchduleState = this.handleCloneAppointmentState()
    clonedSchduleState[e.target.id] = e.target.value
    this.setState({
      appointmentState: clonedSchduleState,
    })
  }

  handleUploadChange = (e: any) => {
    this.setState({
      image: e.target.files[0],
    })
  }

  handleChange = (address: Address) => {
    const clonedSchduleState = this.handleCloneAppointmentState()
    clonedSchduleState["address"] = address
    this.setState({
      appointmentState: clonedSchduleState,
    })
  }

  handleClearNotification = () => {
    this.setState({
      notificationAttribute: {
        title: "",
        desc: "",
        type: "",
        visible: false,
      },
    })
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  handleClearAppointment = () => {
    this.setState({
      appointmentState: {
        phone: "",
        email: "",
        name: "",
        address: {
          name: "",
          lat: 0,
          lng: 0,
        },
        image: "",
        preferredDay: "",
        preferredTime: "",
        additionRemark: "",
        estimatedWeight: 0,
      },
      image: "",
    })
  }

  handleNotification = (title: string, desc: string, type: string) => {
    this.setState({
      notificationAttribute: {
        title: title,
        desc: desc,
        type: type,
        visible: true,
      },
    })
  }

  handleLoading = (loading: boolean, text: string) => {
    this.setState({
      loading: loading,
      loadingText: text,
    })
  }

  handleSubmit = async () => {
    this.handleLoading(true, "Please hold on while we are adding your schedule")
    let conditionsList: string[] = [
      "phone",
      "name",
      "preferredTime",
      "preferredDay",
      "estimatedWeight",
      "address",
    ]
    const clonedScheduleError = JSON.parse(
      JSON.stringify(this.state.appointmentStateError)
    )
    handleScheduleCondition(
      this.state.appointmentState,
      clonedScheduleError,
      conditionsList
    )
    this.setState(
      {
        appointmentStateError: clonedScheduleError,
      },
      async () => {
        if (
          !this.state.appointmentStateError.phoneError &&
          !this.state.appointmentStateError.nameError &&
          !this.state.appointmentStateError.preferredTimeError &&
          !this.state.appointmentStateError.preferredDayError &&
          !this.state.appointmentStateError.estimatedWeightError &&
          !this.state.appointmentStateError.imageError &&
          !this.state.appointmentStateError.addressError
        ) {
          const userUUID =
            this.props.authStore.user?.id ??
            (await checkAppointmentUserExist(this.state.appointmentState))
          if (userUUID) {
            const clonedNewAppotnmentState = JSON.parse(
              JSON.stringify(this.state.appointmentState)
            )
            if (this.state.image)
              clonedNewAppotnmentState["image"] = this.state.image

            const createScheduleResp = await createSchedule(
              clonedNewAppotnmentState,
              this.props.authStore.user,
              this.props.utilsStore.firebase,
              userUUID
            )
            if (createScheduleResp) {
              this.handleNotification(
                "Create Schedule Failed",
                "Sorry, your appointment is unsuccessful. Please try again.",
                "fail"
              )
            } else {
              this.handleNotification(
                "Appointment Created Successfully",
                "We have created your appointment. Our team will be contacting you in 1-3 working days 😄. In the meantime, you can also sign in with your phone number to view your appointment updates",
                "success"
              )
              this.handleClearAppointment()
            }
          } else {
            this.handleNotification(
              "Create Schedule Failed",
              "Sorry, your appointment is unsuccessful. Please try again.",
              "fail"
            )
          }
          this.handleLoading(false, "")
        } else {
          this.handleLoading(false, "")
        }
      }
    )
  }

  renderContent = () => {
    return (
      <>
        <div className="relative px-4 sm:px-0">
          <div className="bg-white shadow-xl grid grid-cols-1 lg:grid-cols-2">
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <form
                action="#"
                method="POST"
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Preferred Day of Collection
                  </label>
                  <Select
                    id="preferredDay"
                    placeholder="Select the Preferred Day"
                    value={this.state.appointmentState.preferredDay}
                    options={preferredDayOptions}
                    onChange={this.handleSelectChange}
                    error={this.state.appointmentStateError.preferredDayError}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Preferred Time for Collection
                  </label>
                  <Select
                    id="preferredTime"
                    placeholder="Select the Preferred Day"
                    value={this.state.appointmentState.preferredTime}
                    options={preferredTimeOptions}
                    onChange={this.handleSelectChange}
                    error={this.state.appointmentStateError.preferredTimeError}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <Input
                    id="phone"
                    className="mt-1"
                    placeholder={utilPhonePlaceholder()}
                    value={this.state.appointmentState.phone}
                    error={this.state.appointmentStateError.phoneError}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Customer Name
                  </label>
                  <Input
                    id="name"
                    className="mt-1"
                    placeholder={"John Doe"}
                    value={this.state.appointmentState.name}
                    error={this.state.appointmentStateError.nameError}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1 ">
                  <AddressInput
                    id="address"
                    className="mt-1"
                    value={this.state.appointmentState.address}
                    error={this.state.appointmentStateError.addressError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Upload an image of the collected UCO (Optional)
                  </label>
                  <UploadInput
                    accept=".png,.jpg,.jpeg"
                    onChange={this.handleUploadChange}
                    file={this.state.image}
                    error={this.state.appointmentStateError.imageError}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Estimate Weight of UCO (kg)
                  </label>
                  <Input
                    id="estimatedWeight"
                    className="mt-1"
                    placeholder="Enter your estimated weight"
                    type="number"
                    value={this.state.appointmentState.estimatedWeight.toString()}
                    error={
                      this.state.appointmentStateError.estimatedWeightError
                    }
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Additional Remarks (Optional)
                  </label>
                  <Input
                    id="additionRemark"
                    className="mt-1"
                    placeholder="Enter your additional remarks"
                    type="textarea"
                    value={this.state.appointmentState.additionRemark}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end mt-5">
                  <Button
                    className="w-full sm:w-auto"
                    text="Submit"
                    type="normal"
                    onClick={this.handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <Whatsapp />
        <Notification
          notificationAttribute={this.state.notificationAttribute}
          onClick={this.handleClearNotification}
        />
        <SEO
          title="Arus Oil - Create An Appointment"
          description="Book a door-to-door collection for your used cooking oil (UCO) recycling needs with a minimum of 5 kg! Start recycling by setting up a new appointment with us today. "
        />
        <Header page="Create Appointment" />
        <Overlay loading={this.state.loading} text={this.state.loadingText} />
        <div className="py-10">
          <header>
            <div className="max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Create An Appointment
              </h1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="mt-8">
                <div className="block mt-2">{this.renderContent()}</div>
              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(CreateAppointmentPage)
